<script lang="ts" setup>
import type { ButtonSize } from '@/components/button/SkrButton.vue'

const props = defineProps<{
  size?: ButtonSize
  stacked?: boolean
  filled?: boolean
  reversed?: boolean
}>()

if (props.size) {
  provide('buttonSize', props.size)
}
</script>

<template>
  <div
    class="skr-buttons"
    :class="{ 'skr-buttons--stacked': stacked, 'skr-buttons--filled': filled, 'skr-buttons--reversed': reversed }"
  >
    <slot />
  </div>
</template>

<style lang="sass" scoped>
.skr-buttons
  display: flex
  gap: 0.5rem
  flex-direction: row
  justify-content: space-between
  align-items: center
  width: 100%
  margin-top: 1rem
  margin-bottom: 1rem
  &--stacked
    flex-direction: column
    align-items: stretch
    justify-content: center
    &.skr-buttons--reversed
      flex-direction: column-reverse
  &--filled:not(.skr-buttons--stacked)
    :deep(.skr-button)
      flex: 1
      min-width: max-content
</style>
